<script lang="ts">
	import { notifications, type NotificationType } from '$lib/notifications';
	import { chain, flow, wallet, fallback } from '$lib/wallet';
	import { sleep } from '$lib/time';
	import ModalDialog from '$lib/components/app/ModalDialog.svelte';
	import NameLink from '$lib/components/app/NameLink.svelte';
	import type { BigNumberish } from '@ethersproject/bignumber';
	import type { Contract } from '@ethersproject/contracts';
	import { namehash, isValidName } from '@ethersproject/hash';
	import { formatEther, parseUnits } from '@ethersproject/units';
	import isValidDomain from 'is-valid-domain';
	import { ownerAddress } from '$lib/config';

	type PriceBreakdown = {
		finalPrice: BigNumberish;
		basePrice: BigNumberish;
		discountedPrice: BigNumberish;
		isEligibleForDiscount: boolean;
	};

	const discountTable: { [nameLength: number]: string } = {
		3: '10',
		4: '20',
		5: '30',
		6: '50'
	};

	let name: string;
	let selectedName: string;
	let isAvailable: boolean;
	let hasNonASCIICharacters: boolean;

	let priceBreakdown: PriceBreakdown;
	let basePrice: string;
	let discountedPrice: string;
	let finalPrice: string;
	let isEligibleForDiscount: boolean;
	let discount: string;

	let isLoading: boolean;
	let isSearchingName: boolean;
	let isRegisteringName: boolean;

	const registerModalId = 'register-modal';
	let registerModal: ModalDialog;

	async function searchName(_name: string) {
		try {
			isSearchingName = true;

			if (_name.length < 3) {
				notifications.enqueue({
					title: 'Invalid name',
					text: `The name ${_name}.kcc is too short`,
					type: 'error',
					delay: 2500
				});
				return;
			}

			if (!isValidName(`${_name}.kcc`) || !isValidDomain(`${_name}.kcc`, { allowUnicode: true })) {
				notifications.enqueue({
					title: 'Invalid name',
					text: `The name ${_name}.kcc is not a valid domain name`,
					type: 'error',
					delay: 2500
				});
				return;
			}

			let contracts: { [x: string]: Contract };
			while (!contracts) {
				if ($chain.state === 'Ready') {
					contracts = $chain.contracts;
				} else if ($fallback.state === 'Ready') {
					contracts = $fallback.contracts;
				}
				await sleep(150);
			}

			isAvailable = await contracts.KNSRegistrarController.available(_name);
			selectedName = _name;
		} catch (e) {
			console.log('fooking exploded:', e);
		} finally {
			isSearchingName = false;
		}
	}

	async function prepareForRegistration() {
		try {
			isSearchingName = true;

			if ($chain.state !== 'Ready') {
				await flow.connect();
			}
			const owner = $wallet.address;

			registerModal.setVisibility(true);

			console.log(await $chain.contracts.KNSPriceOracle.witnetPriceRouter());
			console.log(await $chain.contracts.KNSPriceOracle.kcsUSDTPriceFeed());
			priceBreakdown = await $chain.contracts.KNSPriceOracle.getNamePriceBreakdownForBuyer(
				selectedName,
				owner
			);
		} catch (e) {
			console.log('fooking explodaddy:', e);
		} finally {
			isSearchingName = false;
		}
	}

	async function register(_name: string, _owner: string) {
		try {
			registerModal.setVisibility(false);

			if ($chain.state !== 'Ready') {
				await flow.connect();
				_owner = $wallet.address;
			}

			let tx: any;
			const price = ownerAddress === $wallet.address ? parseUnits('0') : priceBreakdown.finalPrice;
			await flow.execute(async (contracts) => {
				const _resolver = await contracts.KNSRegistry.resolver(namehash('kcc'));
				tx = await contracts.KNSRegistrarController.register(
					_name,
					_owner,
					_resolver,
					_owner,
					false,
					{ value: price, gasPrice: parseUnits('1.02', 'gwei') }
				);
			});
			isRegisteringName = true;
			notifications.enqueue({
				title: 'Transaction submitted',
				text: `Registration for the name ${_name}.kcc has been requested`,
				type: 'success',
				delay: 2500
			});

			await tx.wait();
			notifications.enqueue({
				title: 'Transaction confirmed',
				text: `The name ${_name}.kcc has been registered`,
				type: 'success',
				delay: 2500
			});

			await resetUI();
		} catch (e) {
			console.log('fooking explodood', e);
			notifications.enqueue({
				title: 'Name not registered',
				text: `Error: ${e.message}`,
				type: 'error',
				delay: 2500
			});
		} finally {
			isRegisteringName = false;
		}
	}

	async function resetUI() {
		name = '';
		selectedName = '';
	}

	function formatPrice(price: BigNumberish) {
		const res = formatEther(price);
		return (+res).toFixed(4);
	}

	$: isLoading = isSearchingName || isRegisteringName || $flow.inProgress;

	$: hasNonASCIICharacters = selectedName && selectedName?.match(/[^ -~]/) !== null;

	$: if (priceBreakdown) {
		basePrice = formatPrice(priceBreakdown.basePrice);
		discountedPrice = formatPrice(priceBreakdown.discountedPrice);
		finalPrice = formatPrice(priceBreakdown.finalPrice);
		isEligibleForDiscount = priceBreakdown.isEligibleForDiscount;
		discount = discountTable[selectedName.length] ?? '50';
	}

	//$: loading = $transactions.some((tx) => tx.status === 'pending') || $flow.inProgress;

	let i = 0;
	function notify() {
		const types: NotificationType[] = ['success', 'error', 'warning', 'info'];
		for (const type of types) {
			notifications.enqueue({
				title: 'Name registered',
				text: 'The name gilgames.kcc has been registered ' + i++,
				delay: 350000,
				type: type
			});
		}
	}
</script>

{#if hasNonASCIICharacters}
	<div class="flex items-center justify-center">
		<div class="flex alert alert-warning shadow-lg w-[91%] md:w-2/3 mt-4">
			<div>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					class="stroke-current flex-shrink-0 h-6 w-6"
					fill="none"
					viewBox="0 0 24 24"
					><path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
					/></svg
				>
				<span class="ml-2"
					>The name you entered contains non-ASCII characters. Please note that some Unicode
					characters look identical or very similar to English letters. Their incorrect or malicious
					usage is a possibility for <a
						href="https://en.wikipedia.org/wiki/IDN_homograph_attack"
						target="_blank"
						class="link">homograph attacks</a
					>.
				</span>
			</div>
		</div>
	</div>
{/if}

<div class="flex px-3 py-5 md:py-24 items-center justify-center">
	<div class="card bg-base-200 text-neutral-content shadow-xl">
		<div class="card-body items-center text-center">
			<h2 class="card-title">Search for a .kcc name</h2>
			<div class="card-body">
				<div class="form-control">
					<div class="input-group">
						<input
							type="text"
							placeholder="Search names"
							class="input input-bordered"
							bind:value={name}
							disabled={isLoading}
							on:input={() => (name = name.replace(/\./g, '').toLowerCase())}
						/>
						<button
							class="btn btn-square"
							disabled={isLoading || !name}
							on:click={async () => await searchName(name)}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								class="h-6 w-6"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								><path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
								/></svg
							>
						</button>
					</div>
				</div>
				<!-- <button class="btn" on:click={notify}>notify</button> -->
				{#if !!selectedName}
					<div class="mt-5">
						<b><NameLink name="{selectedName}.kcc" /></b> is {isAvailable ? '' : 'not'} available
					</div>
					{#if isAvailable}
						<div>
							<button
								class="btn btn-sm"
								disabled={isLoading}
								on:click={async () => await prepareForRegistration()}>Register</button
							>
						</div>
					{/if}
				{/if}
			</div>
		</div>
	</div>
</div>

<ModalDialog bind:this={registerModal} modalId={registerModalId} showCloseButton={true}>
	{#if isSearchingName || !priceBreakdown}
		<h3 class="text-lg font-bold">Calculating price</h3>
		<progress class="progress progress-primary w-56 justify-center" />
	{:else}
		<h3 class="text-lg font-bold">Register name</h3>
		<div class="mt-3 py-4 px-6 w-full">
			<div class="flex">
				<div>Name</div>
				<div class="text-right grow font-bold">{selectedName}.kcc</div>
			</div>
			<div class="flex">
				<div>Base price</div>
				<div class="text-right grow font-bold">{basePrice} KCS</div>
			</div>
			<div class="flex">
				<div>HODLer discount</div>
				{#if isEligibleForDiscount}
					<div class="text-right grow font-bold">
						-{discount}%
					</div>
				{:else}
					<div class="text-right grow italic">Not eligible</div>
				{/if}
			</div>
			<div class="divider" />
			<div class="flex">
				<div class="text-lg font-bold">Total:</div>
				<div class="text-right grow font-bold">{finalPrice} KCS</div>
			</div>
			<div class="text-right mt-6">
				<button
					class="btn"
					on:click={async () => await register(selectedName, $wallet.address)}
					disabled={isLoading}>Confirm</button
				>
			</div>
		</div>
	{/if}
</ModalDialog>
